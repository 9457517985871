<template lang="pug">
  el-form
    el-row(:gutter="10")
      el-col(:xs="24" :sm="8", :lg="6")
        el-form-item(label="By Keyword" label-position="top" label-width="auto")
          el-input(type="text", placeholder="Search By Keyword...", size="mini", v-model="paginate.keyword", v-debounce:300ms="fetchData")

      el-col(:xs="12", :sm="6", :lg="4")
        el-form-item(label="Brand" label-position="top" label-width="auto")
          el-select.w-100(v-if="brand_options" filterable="", size="mini"  v-model='filters.brand', placeholder='By Brand', @change="fetchData")
            el-option.text-bold(label='All', value='')
            el-option(v-for="o of brands_options", :label="o.t", :value="o.v" )

      el-col(:xs="12", :sm="filters.funnel.length > 1 ? 10 : 6", :lg="filters.funnel.length > 1 ? 10 : 4")
        el-form-item(label="Funnel" label-position="top" label-width="auto" )
          el-select.w-100(v-if="funnel_options" filterable="",  multiple="", size="mini"  v-model='filters.funnel', placeholder='By Funnel', @change="fetchData" :disabled="!filters.brand")
            el-option.text-bold(label='All', value='')
            el-option(v-for="o of funnels_options", :label="o.t", :value="o.v", v-if="o.b===filters.brand")

    el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
      el-table-column(align='center', label='#' :width='55')
        template(slot-scope='scope')
          | {{ scope.row.id }}
      el-table-column( align='center', label="Type")
        template(slot-scope="scope")
          span.chooseable() {{ scope.row.type || '-' }}
          small.ml-1.text-success(v-if="scope.row.note") ({{ scope.row.note }})


      el-table-column( align='center', label="Brand")
        template(slot-scope="scope")
          span.chooseable() {{ scope.row.brand ? scope.row.brand.name : '-' }}

      el-table-column( align='center', label="Associated Funnels")
        template(slot-scope="scope")
          el-popover(
            placement="top-start"
            title="Associated Funnels"
            :width="scope.row.funnel.length === 0 ? 200 : 350"
            trigger="hover"

          )
            router-link.nav-link( v-for="l of scope.row.funnel" :to="{name: 'iframe-edit-funnel', params: { id: l.id }}") {{ l.name }}
            span.chooseable(slot="reference") {{ scope.row.funnel.length === 0 ? '-' : 'Hover To View'}}

      el-table-column( align='center', label="Associated Spreadsheets")
        template(slot-scope="scope")
          el-popover(
            placement="top-start"
            title="Associated Spreadsheets"
            :width="scope.row.funnel.length === 0 ? 200 : 350"
            trigger="hover"

          )
            router-link.nav-link( v-for="l of scope.row.spreadsheet" :to="{name: 'iframe-edit-spreadsheet', params: { id: l.id }}") {{ l.hash }}
            span.chooseable(slot="reference") {{ scope.row.spreadsheet.length === 0 ? '-' : 'Hover To View'}}

      el-table-column(align="center" label="Actions" :width="175")
        template(slot-scope="scope")
          .d-flex.justify-content-center
            el-button(v-b-tooltip.html.hover.top="", title="Show Integration", type="success", icon="el-icon-tickets", size="small", @click="$router.push({name: 'iframe-edit-integration', params: { id: scope.row.id }})")
            el-button(v-b-tooltip.html.hover.top="", title="Clone Integration", type="info", icon="el-icon-star-on", size="small", @click="$router.push({name: 'iframe-add-integration', query: { id: scope.row.id }})")
    .d-flex.justify-content-between
      b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")


      el-row
        el-col(:span="24")
          el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
            el-option(label="10 Per Page", :value="10")
            el-option(label="20 Per Page", :value="20")
            el-option(label="50 Per Page", :value="50")
            el-option(label="100 Per Page", :value="100")
            el-option(label="200 Per Page", :value="200")
</template>

<style lang="scss">

</style>


<script lang="js">
export default {
  name: 'integration-modal',
  data() {
    return {
      busy: false,
      list: [],
      count: 0,
      paginate: {
        keyword: '',
        limit: 10,
        page: 1
      },
      filters: {
        brand: '',
        funnel: []
      },
      brand_options: [],
      brands_options: [],
      funnel_options: [],
      funnels_options: []
    }
  },
  async mounted() {
    await this.fetchData();
    let result = await this.$apix.sendHttpRequest('GET', 'components/options', {filters: '1e0c327a-73cf-4159-b154-ec498ade6dcd,49f06cec-46b0-4237-bf9a-d4d3969e5af6'});
    this.brands_options = result.brand;
    this.funnels_options = result.funnel_extra;
  },

  methods: {
    async fetchData() {
      setTimeout(() => {
        let body = {...this.paginate, ...this.filters};
        this.$apix.sendHttpRequest('GET', 'integration/list', body)
          .then(res => {
            this.list = res.rows;
            this.count = res.count;
          })
      }, 200)

    }
  }
};
</script>
