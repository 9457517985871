<template lang="pug">
	div
		el-form
			h5.mb-1 Basic
			el-row(:gutter="12")
				el-col(:xs="24" :sm="6")
					el-form-item(label="Type: ")
						el-input(:readonly="true" :value="entity.type + (entity.note ? ` (${entity.note})` : '')")
				el-col(:xs="24" :sm="18")
					el-form-item(label="API URL: ")
						el-input(:readonly="true" :value="entity.api_url")
			el-divider
			h5.mb-1 Creds
			el-row(:gutter="12")
				el-col(:xs="24", :sm="8", v-for="o of Object.entries(entity.creds)")
					el-form-item(:label="o[0]")
						el-input(:readonly="true" :value="o[1]")
			el-divider
			h5.mb-1 Misc
			el-row(:gutter="12")
				el-col(:xs="24", :sm="8", v-for="o of Object.entries(entity.misc)")
					el-form-item(v-if="o[0] !== 'api_url'" :label="o[0]")
						el-input(:readonly="true" :value="o[1]")
			el-divider
			h5.mb-1 Endpoints
			el-row(:gutter="12")
				el-col(:xs="24", :sm="6", v-for="o of Object.entries(entity.misc.api_url)")
					el-form-item(:label="o[0]")
						el-input(:readonly="true" :value="o[1]")

</template>


<style lang="scss">

</style>


<script lang="js">
	const clearilters = (data) => {
		let body = {...data};

		if (!body.funnel_id) delete body.funnel_id;
		return {...body}
	};

	export default {
		name: 'integration-list',
		props: ['id', 'type'],
		data() {
			return {
				busy: false,
				entity: {},
			}
		},
		async created() {
			if(this.id) await this.fetchData();
		},
		watch: {
			async id() {
				if(this.id) await this.fetchData();
			}
		},
		methods: {
			async fetchData() {
				if (this.type === 'googleSheets') this.entity = await this.$apix.sendHttpRequest('GET', 'integration/get', {id: this.id})
				else if (this.type === 'funnel') this.entity = await this.$apix.sendHttpRequest('GET', 'integration/get', {funnel_id: this.id})
			}
		}
	}
</script>
