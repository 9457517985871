<template lang="pug">
	div
		el-form
			el-row(:gutter="10")
				el-col(:xs="24")
					el-form-item(label="By Keyword" label-position="top" label-width="auto")
						el-input(type="text", placeholder="Search By Keyword...", v-model="paginate.keyword", v-debounce:300ms="fetchData")
			el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='' @selection-change="handleSelectionChange" )
				el-table-column(type="selection" :width="55"  )

				el-table-column(align='center', label='#' :width='55')
					template(slot-scope='scope')
						el-tooltip(:content="'UUID: ' + scope.row.uuid")
							span {{ scope.row.id }}
				el-table-column( align='center', label="UUID")
					template(slot-scope='scope')
						el-tooltip(:content="'UUID: ' + scope.row.uuid + ', You can use UUID instead of bran + funnel pair to hide the names. Example use: https://example.com/step-1/' + scope.row.uuid")
							small {{ scope.row.uuid }}
				el-table-column( align='center', label="Brand" v-if="!brand_name")
					template(slot-scope="scope")
						span {{ scope.row.brand ? scope.row.brand.name : '-' }}
				el-table-column( align='center', label="Family")
					template(slot-scope="scope")
						span(v-if="scope.row.funnel_family_id") {{ scope.row.funnel_family.name }}
						span.text-danger(v-else="")
							i No Classification
				el-table-column( align='center', label="Link / Name" :width="130")
					template(slot-scope="scope")
						span.chooseable() {{ scope.row.name || '-' }}&nbsp;
						small.text-success {{ scope.row.note ? `(${scope.row.note})` : '' }}
						//small.text-success.d-block {{'#:' + scope.row.uuid }}
				el-table-column( align='center', label="SMS" :width="50")
					template(slot-scope="scope")
						font-awesome-icon.icon.alt.text-danger(v-if="!scope.row.sms", :icon="['fas', 'times']")
						font-awesome-icon.icon.alt.text-success(v-else="", :icon="['fas', 'check']")
				el-table-column( align='center', label="Language")
					template(slot-scope="scope")
						span.chooseable() {{ scope.row.language ? languagesMap[scope.row.language] : '-' }}
				el-table-column( align='center', label="Redirects")
					template(slot-scope="scope")
						.d-block
							span.chooseable(v-b-tooltip.html.hover.right="", :title="scope.row.redirect_step2" @click="openLink(scope.row.redirect_step2)") Link to step 2
						.d-block
							span.chooseable(v-b-tooltip.html.hover.right="", :title="scope.row.redirect_app" @click="openLink(scope.row.redirect_app)") Link to App
				el-table-column( align='center', label="JustClick")
					template(slot-scope="scope")
						span.d-block.text-danger
							b.clickable(v-b-tooltip.html.hover.right="", :title="`<label class='d-block'>Success: ${scope.row.jc_success || '-'}</label><label class='d-block'>Failed: ${scope.row.jc_failed || '-'}</label><label class='d-block'>Prelander: ${scope.row.jc_pre || '-'}</label><label class='d-block'>Step 1: ${scope.row.jc_step_1 || '-'}</label><label class='d-block'>Step 2: ${scope.row.jc_step_2 || '-'}</label>`") Hover
				//el-table-column( align='center', label="Styles")
					template(slot-scope="scope")
						span.d-block.clickable(v-b-tooltip.html.hover.right="", :title="scope.row.css") CSS:hover
						span.d-block.text-secondary.clickable(v-b-tooltip.html.hover.right="", :title="scope.row.fonts") Fonts:hover
				el-table-column(align="center" label="Actions" :width="175")
					template(slot-scope="scope")
						.d-flex.justify-content-center
							el-tooltip()
								template(slot="content")
									span Show Links
								el-button.small-icon(type="primary", icon="el-icon-link", size="small", @click="showLinks(scope.row)")

							el-tooltip()
								template(slot="content")
									span Show Integration
								el-button.small-icon(type="success", icon="el-icon-tickets", size="small", @click="$emit('funnel', scope.row.id)")

							el-tooltip()
								template(slot="content")
									span.d-block Edit funnel
									a.chooseable.d-block(@click="openLink(`/iframe-form/edit-funnel/${scope.row.id}`)" style="color: yellow") Open in new tab
								el-button.small-icon(type="info", icon="el-icon-edit", size="small", @click="$router.push({name: 'iframe-edit-funnel', params: {id: scope.row.id}})" )

							el-tooltip()
								template(slot="content")
									span.d-block Clone funnel
									a.chooseable.d-block(@click="openLink(`/iframe-form/add-funnel?id=${scope.row.id}`)" style="color: yellow") Open in new tab
								el-button.small-icon(type="warning", icon="el-icon-star-on", size="small", @click="$router.push({name: 'iframe-add-funnel', query: {id: scope.row.id}})")

							//el-button.small-icon(v-b-tooltip.html.hover.top="", title="Remove funnel", type="danger", size="small",  icon="el-icon-remove", @click="removeEntity(scope.row.id)")

			.d-flex.justify-content-between
				b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")


				el-row
					el-col(:span="24")
						el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
							el-option(label="10 Per Page", :value="10")
							el-option(label="20 Per Page", :value="20")
							el-option(label="50 Per Page", :value="50")
							el-option(label="100 Per Page", :value="100")
							el-option(label="200 Per Page", :value="200")
		el-dialog(title='Funnel links', :visible.sync='linksModal',  center='', @closed="linksModal = false")
			el-row(v-if="currentFunnel" :gutter="12")
				el-col(:xs="24" :sm="12")
					h6
						b Brand-Funnel pair based
					.d-block
						label Step-Pre:&nbsp;&nbsp;
							a.chooseable(@click="openLink(`${iframe_path}/step-pre/${currentFunnel.brand.name}/${currentFunnel.name}`)") {{`${iframe_path}/step-pre/${currentFunnel.brand.name}/${currentFunnel.name}`}}
					.d-block
						label Step 1:&nbsp;&nbsp;
							a.chooseable(@click="openLink(`${iframe_path}/step-1/${currentFunnel.brand.name}/${currentFunnel.name}`)") {{`${iframe_path}/step-1/${currentFunnel.brand.name}/${currentFunnel.name}`}}
					.d-block
						label Step 2:&nbsp;&nbsp;
							a.chooseable(@click="openLink(`${iframe_path}/step-2/${currentFunnel.brand.name}/${currentFunnel.name}`)") {{`${iframe_path}/step-2/${currentFunnel.brand.name}/${currentFunnel.name}`}}
				el-col(:xs="24" :sm="12")
					h6
						b UUID Hash based
					.d-block
						label Step-Pre:&nbsp;&nbsp;
							a.chooseable(@click="openLink(`${iframe_path}/step-pre/${currentFunnel.uuid}`)") {{`${iframe_path}/step-pre/${currentFunnel.uuid}`}}
					.d-block
						label Step 1:&nbsp;&nbsp;
							a.chooseable(@click="openLink(`${iframe_path}/step-1/${currentFunnel.uuid}`)") {{`${iframe_path}/step-1/${currentFunnel.uuid}`}}
					.d-block
						label Step 2:&nbsp;&nbsp;
							a.chooseable(@click="openLink(`${iframe_path}/step-2/${currentFunnel.uuid}`)") {{`${iframe_path}/step-2/${currentFunnel.uuid}`}}

</template>


<style lang="scss" scoped>
.small-icon {
	padding: 9px;
}
</style>


<script lang="js">
const clearilters = (data) => {
	let body = {...data};

	if (!body.brand_id) delete body.brand_id;
	return {...body}
};

export default {
	name: 'funnel-list',
	props: ['brand_id','brand_name'],
	data() {
		return {
			linksModal: false,
			currentFunnel: null,
			languagesMap: {},
			busy: false,
			list: [],
			count: 0,
			paginate: {
				keyword: '',
				limit: 10,
				page: 1
			},
		}
	},
	async created() {
		await this.fetchData();
		this.languagesMap = await this.getLanguages();
	},
	watch: {
		async brand_id() {
			await this.fetchData();
		}
	},
	computed: {
		iframe_path() {
			return process.env.VUE_APP_IFRAME
		}
	},
	beforeDestroy() {
		this.$store.dispatch('setItgSelFunLst', undefined)
	},
	methods: {
		async getLanguages() {
			return this.$apix.sendHttpRequest('GET', 'components/options', {filters: 'e91556b8-c5da-442f-a0bf-31f2ce567abe'})
				.then(options => {
					const hash = {};
					options.languages.forEach(l => hash[l.v] = l.t);
					return hash;
				})
		},
		async fetchData() {
			setTimeout(() => {
				this.$apix.sendHttpRequest('GET', 'funnel/list', clearilters(Object.assign({}, {...this.paginate}, {...{brand_id: this.brand_id}})))
						.then(res => {
							this.list = res.rows;
							this.count = res.count;
						})
			}, 0)
		},
		openLink(url) {
			window.open(url, "_blank")
		},
		showLinks(funnel) {
			this.linksModal = true;
			this.currentFunnel = funnel;
		},
		handleSelectionChange($event) {
			this.$store.dispatch('setItgSelFunLst', $event);

		}
	}
}
</script>
