<template lang="pug">
	.d-flex.justify-content-between

		b-pagination.mt-3(v-if="count", v-model="cPage", :total-rows="count", :per-page="cLimit", aria-controls="data-list", @change="($event) => {$emit('update:page',cPage);func($event)}")
		//pre {{cLimit}}
		el-row(v-if="count")
			el-col(:span="24")
				el-select.mt-3(filterable="", :value="cLimit", @change="($event) => {$emit('update:limit', cLimit);func($event)}")
					el-option(label="10 Per Page", :value="10")
					el-option(label="20 Per Page", :value="20")
					el-option(label="50 Per Page", :value="50")
					el-option(label="100 Per Page", :value="100")
					el-option(label="200 Per Page", :value="200")
</template>


<style lang="scss">

</style>


<script lang="js">
	export default {
		name: 'ds-paginate',
		props: {
			page: Number,
			limit: Number,
			count: Number,
			func: Function
		},
		data() {
			return {
				// cPage: this.page,
				// cLimit: this.limit
			}
		},
		computed: {
			cPage: {
				get() {
					return this.page;
				},
				set(val) {
					this.page = val;
					this.$emit('page', val)
				}
			},
			cLimit: {
				get() {
					return this.limit;
				},
				set(val) {
					this.limit = val;
					this.$emit('limit', val)
				}
			},
			cCount: {
				get() {
					return this.count;
				},
				set(val) {
					this.count = val;
					this.$emit('count', val)
				}
			}
		}
	}
</script>