<template lang="pug">
	#fum-section-root
		#fun-section-instructions
			p
				| This tool is meant to override funnel settings in bulk - can be useful to change specific toggles or other options. Use with care.&nbsp;

				i.text-danger Changing any of the settings below will affect all the selected funnels!
			p You can also ignore changing, leaving the properties with their current values - in <b>toggles</b> you must select "leave", in <b>select boxes</b> you must choose the&nbsp;
				| first option "- Select from the list -" and in the <b>input fields</b> leave empty or type "-1"

		#fum-section-radio
			el-form(:form="form" :model="form")
				el-row.mt-3()
					h5 Basic Toggles
				el-row(:gutter="24")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="SMS" label-position="top")
							el-radio-group.w-100(size="mini" v-model="form.sms" :fill="form.sms === true ? '#409EFF' : form.sms === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="Password" label-position="top")
							el-radio-group.w-100(size="mini" v-model="form.password" :fill="form.password === true ? '#409EFF' : form.password === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="Allow Duplicates" label-position="top")
							el-radio-group.w-100(size="mini" v-model="form.allow_duplicates" :fill="form.allow_duplicates === true ? '#409EFF' : form.allow_duplicates === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="Is Strict Phone" label-position="top")
							el-radio-group.w-100(size="mini" v-model="form.is_strict_phone" :fill="form.is_strict_phone === true ? '#409EFF' : form.is_strict_phone === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="Redirect Params" label-position="top")
							el-radio-group.w-100(size="mini" v-model="form.allow_redirect_params_reg" :fill="form.allow_redirect_params_reg === true ? '#409EFF' : form.allow_redirect_params_reg === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
				el-row.mt-3
					h5 iFrame Related Toggles
				el-row(:gutter="12")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="Horizontal Form - Pre" label-position="top")
							el-radio-group.w-100(size="mini" v-model="form.horizontal_form_pre" :fill="form.horizontal_form_pre === true ? '#409EFF' : form.horizontal_form_pre === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="Horizontal Form - Step1" label-position="top")
							el-radio-group.w-100(size="mini" v-model="form.horizontal_form_step1" :fill="form.horizontal_form_step1 === true ? '#409EFF' : form.horizontal_form_step1 === -1 ? '#c9c9c9' : 'red'")
								el-radio-button(:label="true") On
								el-radio-button(:label="false") Off
								el-radio-button(:label="-1") Leave
				el-row.mt-3
					h5 Input and Select elements
				el-row(:gutter="24")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="Note")
							el-input(size="mini" v-model="form.note" placeholder="Override the funnel's note")

					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="CSS - Step Pre")
							el-select.w-100(filterable="" size="mini" v-model="form.css_pre" placeholder="Override CSS for step pre")
								el-option(label="- Select from the list -" :value="-1")
								el-option(v-for="o of options.styles" :label="o.t" :value="o.v")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="CSS - Step 1")
							el-select.w-100(filterable="" size="mini" v-model="form.css_s1" placeholder="Override CSS for step 1")
								el-option(label="- Select from the list -" :value="-1")
								el-option(v-for="o of options.styles" :label="o.t" :value="o.v")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="CSS - Step 2")
							el-select.w-100(filterable="" size="mini" v-model="form.css_s2" placeholder="Override CSS for step 2")
								el-option(label="- Select from the list -" :value="-1")
								el-option(v-for="o of options.styles" :label="o.t" :value="o.v")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="Language")
							el-select.w-100(filterable="" size="mini" v-model="form.language" placeholder="Override the funnel's note")
								el-option(label="- Select from the list -" :value="-1")
								el-option(v-for="o of options.languages" :label="o.t" :value="o.v")
					el-col(:xs="12", :sm="8" :md="6" :lg="4" v-if="form.language !== -1")
						el-tooltip(content="If you select 'translate' all the translation fields (errors, iFrame fields, API responses) will be translated to the selected language. If you select 'leave' - the translations will stay on the same language as they are now")
							el-form-item()
								template(slot="label")
									span Translate Fields/Errors?&nbsp;
										i.el-icon-question.chooseable.no-deco(slot="reference")
								el-select.w-100(size="mini" v-model="form.translate_toggle")
									el-option(:value="false" label="Leave")
									el-option(:value="true" label="Translate")

				el-row.mt-3
					h5 JustClick Settings
					p You can set JustClick groups to funnels in bulk, however, the hook won't be sent without a user been set.
				el-row(:gutter="24")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="JC - User")
							el-select.w-100(filterable="" size="mini" v-model="form.jc_user" placeholder="Override JustClick User")
								el-option(label="- Select from the list -" :value="-1")
								el-option(v-for="o of options.jc_users" :label="o.t" :value="o.v")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="JC Group - Step Pre")
							el-input(size="mini" v-model="form.jc_pre" placeholder="Override JustClick for step pre")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="JC Group- Step 1")
							el-input(size="mini" v-model="form.jc_step_1" placeholder="Override JustClick for step 1")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-form-item(label="JC Group - Step 2")
							el-input(size="mini" v-model="form.jc_step_2" placeholder="Override JustClick for step 2")


				el-row()
					h5.text-left
					| Telegram
				el-row(:gutter="12")
					el-col(:xs="12", :sm="8" :md="6" :lg="4")
						el-tooltip(content="Toggling on will override the list that is currently set. Leaving empty will clear the list." position="top")
							el-form-item(label-position="top")
								template(slot="label")
									span Override Telegram channels?&nbsp;
										i.el-icon-question.chooseable.no-deco(slot="reference")
								el-radio-group.w-100(size="mini" v-model="form.override_tg_channels" :fill="form.override_tg_channels === true ? '#409EFF' :  'red'")
									el-radio-button(:label="true") On
									el-radio-button(:label="false") Off
					el-col(:xs="12", :sm="16" :md="18" :lg="20" v-if="form.override_tg_channels")
						el-form-item(label="Telegram Notification Channel" label-position="top")
							.w-100.text-left.d-block
								el-tag(:key='tag', v-for='tag in form.tg_channel_id', closable='', :disable-transitions='false', @close='handleClose(tag)')
									| {{tag}}
								el-input.input-new-tag(v-if='inputVisible', v-model='inputValue', ref='saveTagInput', size='mini', @keyup.enter.native='handleInputConfirm', @blur='handleInputConfirm')
								el-button.button-new-tag(v-else='', size='small', @click='showInput') + New Tag


</template>

<style lang="scss">

</style>

<script>
// import * as translations from "../../../struct/translates";

export default {
	name: 'funnel-update-modal',
	data() {
		return {
			// TG Related
			inputVisible: false,
			inputValue: '',
			override_tg_channels: false,

			// Payload
			form: {

				// Select / Input
				note: '',
				language: -1,
				jc_pre: '',
				jc_step_1: '',
				jc_step_2: '',
				jc_user: -1,
				tg_channel_id: [],
				css_pre: -1,
				css_s1: -1,
				css_s2: -1,

				// Radio
				sms: -1,
				password: -1,
				allow_duplicates: -1,
				is_strict_phone: -1,
				allow_redirect_params_reg: -1,
				horizontal_form_pre: -1,
				horizontal_form_step1: -1,

				translate_toggle: false,
				override_tg_channels: false,
			},
			options: {
				styles: [],
				languages: [],
				jc_users: [],
				iframe_translations: [],
			}
		}
	},
	methods: {
		setTranslation() {
			// todo - if the language changes,
		},
		handleClose(tag) {
			this.form.tg_channel_id.splice(this.form.tg_channel_id.indexOf(tag), 1);
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		handleInputConfirm() {
			let inputValue = this.inputValue;
			if(!inputValue || inputValue === '') {
				this.inputVisible = false;
				this.inputValue = '';
			} else if (inputValue && inputValue.length > 10 && inputValue.length < 15) {
				this.form.tg_channel_id.push(inputValue);
				this.inputVisible = false;
				this.inputValue = '';
			} else {
				this.$notify.error({title: 'Validation Error', message: 'Validation failed. Telegram channel must consist of either 11 or 14 characters'})
			}
		},
		submit() {
			// console.log(this.form)
			// console.log(this.options.iframe_translations[this.form.language], this.form.translate_toggle)
			const translates = this.form.translate_toggle ? { translation: this.options.iframe_translations[this.form.language] } : {};

			this.$apix.sendHttpRequest('PUT', 'funnel/bulk-update', { form: this.form, ids: this.$store.getters.ITG_SELECTED_FUNNELS_LIST_UUID, ...translates })
					.then(res => {
						console.log(res);
						this.$emit('funnelBulkUpdate', res === 'ok');
						if(res === 'ok') this.$notify.success({ title: 'API Response', message: 'Funnels updated successfully'});
					});
		}
	},
	beforeMount() {
		this.$apix.sendHttpRequest('GET', 'components/options', {filters: 'f06ac207-2c06-4c8f-a94d-eaaf0fabf513,e91556b8-c5da-442f-a0bf-31f2ce567abe,f19ea6af-0f3a-4984-aa88-cff4928c6bf1,1a11188c-a446-4e71-8f39-1751fb5cf74a'})
				.then(res => {
					this.options.styles = res.styles;
					this.options.languages = res.languages;
					this.options.jc_users = res.jc_users;
					this.options.iframe_translations = res.iframe_translations;
				})
	}
}
</script>