<template	lang="pug">
	div
		el-form
			el-row(:gutter="10" type="flex" justify="space-between")
				el-col(:xs="24" :sm="8", :lg="6")
					el-form-item(label="By Keyword" label-position="top" label-width="auto")
						el-input(type="text", placeholder="Search By Keyword...", size="mini", v-model="pagination.keyword", v-debounce:300ms="fetchData")
				el-col(:xs="24" :sm="8", :lg="6")
					el-button(style="float:right" type="success" icon="el-icon-plus" size="mini" @click="$router.push({name: 'iframe-add-niche'})") Add Niche

			el-table.niche-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
				el-table-column(align='center', label='#' :width='55')
					template(slot-scope='scope')
						| {{ scope.row.id }}
				el-table-column(align='center', label='Name' width="220")
					template(slot-scope='scope')
						| {{ scope.row.name }}
				el-table-column(align='center', label='Associated Funnel Families')
					template(slot-scope='scope')
						el-tooltip(v-for="t of scope.row.funnel_family.slice(0, 5)")
							template(slot="content")
								label.d-block <b>Name</b>: {{ t.name + (t.real_funnel_name ? `(${t.real_funnel_name})` : '') }}
								label.d-block <b>ID</b>: {{ t.id }}
							el-tag(:key="t.id" style="padding: 0 8px !important") {{ t.name }}
						el-tooltip(v-if="scope.row.funnel_family.length > 5")
							template(slot="content")
								div(v-for="(t, i) of scope.row.funnel_family.slice(5, scope.row.funnel_family.length)")
									label.d-block <b>Name</b>: {{ t.name + (t.real_funnel_name ? `(${t.real_funnel_name})` : '') }}
									label.d-block <b>ID</b>: {{ t.id }}
									br(v-if="i < scope.row.funnel_family.slice(5, scope.row.funnel_family.length).length - 1")
							el-tag() & Other
				el-table-column(align='center', label='Actions' :width="150")
					template(slot-scope='scope')
						.d-flex.justify-content-center
							el-button(v-b-tooltip.html.hover.top="", title="Show Integration", type="success", icon="el-icon-tickets", size="small", @click="$router.push({name: 'iframe-edit-niche', params: { id: scope.row.id }})")
							el-button(v-b-tooltip.html.hover.top="", title="Clone Integration", type="danger", icon="el-icon-delete", size="small", @click="remove(scope.row.id)")

			.d-flex.justify-content-between
				b-pagination.mt-3(v-model="pagination.page", :total-rows="count", :per-page="pagination.limit", aria-controls="data-list", @change="fetchData")

				el-row
					el-col(:span="24")
						el-select.mt-3(filterable="", v-model="pagination.limit", @change="fetchData")
							el-option(label="10 Per Page", :value="5")
							el-option(label="10 Per Page", :value="10")
							el-option(label="20 Per Page", :value="20")
							el-option(label="50 Per Page", :value="50")
							el-option(label="100 Per Page", :value="100")
</template>

<style		lang="scss">

</style>

<script		lang="js">
	export default {
		name: 'niche-list',
		data() {
			return {
				list: [],
				count: 0,
				busy: false,
				pagination: {
					keyword: '',
					limit: 5,
					page: 1
				},
			}
		},
		created() {
			this.fetchData();
		},
		methods: {
			fetchData() {/// Switch to Axios!!
				this.busy = true;
				setTimeout(() => {
					return this.$apix.sendHttpRequest('GET', 'niche/list', JSON.parse(JSON.stringify(this.pagination)))
						.then(res => {
							this.list = res.rows;
							this.count = res.count;
							this.busy = false;
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
				}, 200)
			},
			remove(id) {
				// todo
				this.$confirm('Are you sure that you want to delete this entry? This operation cannot be undone!', 'Niche Delete Alert')
					.then(async () => {
						this.busy = true;
						return this.$apix.sendHttpRequest('DELETE', 'niche/' + id)
						.then(resp => {
							this.busy = false;
							if (resp.success) this.$notify.success({
								title: 'API Response',
								message: 'the Niche entry was deleted successfully'
							})
							this.fetchData()
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
					})
					.catch(err => {
						console.error(err);
						this.busy = false;
					})
			}
		},
		
	}
</script>